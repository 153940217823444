export const bestSeller_responsive = {
    0:{
        items:2
    },
    950:{
        items:2
    },
    1150:{
        items:2
    },
    1350:{
      items:4
    }
}

export const services_responsive = {
    0:{
        items:1
    },
    950:{
        items:2
    },
    1100:{
        items:3
    }
}


export const mostPopular_responsive = {
    0:{
        items:3
    },
    950:{
        items:3
    },
    1100:{
        items:6
    }
}