import React from 'react';
import firebase from "firebase";


function Admin() {

    const [username, setusername] = React.useState('');
    const [password, setpassword] = React.useState('');
    const [isLogged, setisLogged] = React.useState('');
    const [ticker, setticker] = React.useState('');
    const [upcomingEvent, setupcomingEvent] = React.useState({
        description: '',
        name: '',
        imageUrl: ''
    });
    const [recentEvent, setrecentEvent] = React.useState({
        description: '',
        name: '',
        imageUrl: ''
    });

    function login(){
        if(username == 'admin' && password == 'admin987/?'){
            setisLogged(true);
        } else{
            alert('Incorrect Credentials')
        }
    }


    React.useEffect(()=>{
        firebase.database().ref('ticker').on('value', (snapshot) => {
            setticker(snapshot.val().message);
        });
        firebase.database().ref('events/recent').on('value', (snapshot) => {
            setrecentEvent(prevState => ({
                ...prevState,
                description: snapshot.val().description,
                name: snapshot.val().name,
                imageUrl: snapshot.val().imageUrl
            }))
        });
        firebase.database().ref('events/upcoming').on('value', (snapshot) => {
            setupcomingEvent(prevState => ({
                ...prevState,
                description: snapshot.val().description,
                name: snapshot.val().name,
                imageUrl: snapshot.val().imageUrl
            }))
        });
    }, [])

    if(isLogged){

    return (
  
        <div>
            <div className='w-full h-auto lg:px-20 lg:py-12 py-24 px-5 flex flex-col gap-4'>
                <h2 className='text-center text-lg font-medium mb-6'>Homepage Content</h2>
                <h1 className='text-xl font-semibold'>Ticker</h1>
                <div className='flex gap-2'>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={ticker} onChange={e=>setticker(e.target.value)}  />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('ticker').set({
                            message: ticker
                        });
                    }}>Save</button>
                </div>

                <h1 className='text-xl font-semibold'>Upcoming Event</h1>
                <div className='flex gap-2 items-center'>
                    <label>name:</label>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={upcomingEvent.name} onChange={e=>setupcomingEvent(prevState => ({...prevState,name: e.target.value}))} />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('events/upcoming/name').set(upcomingEvent.name);
                    }}>Save</button>
                </div>
                <div className='flex gap-2 items-center'>
                    <label>description:</label>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={upcomingEvent.description} onChange={e=>setupcomingEvent(prevState => ({...prevState,description: e.target.value}))} />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('events/upcoming/description').set(upcomingEvent.description);
                    }}>Save</button>
                </div>
                <div className='flex gap-2 items-center'>
                    <label>imageUrl:</label>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={upcomingEvent.imageUrl} onChange={e=>setupcomingEvent(prevState => ({...prevState,imageUrl: e.target.value}))} />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('events/upcoming/imageUrl').set(upcomingEvent.imageUrl);
                    }}>Save</button>
                </div>

                <h1 className='text-xl font-semibold'>Recent Event</h1>
                <div className='flex gap-2 items-center'>
                    <label>name:</label>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={recentEvent.name} onChange={e=>setrecentEvent(prevState => ({...prevState,name: e.target.value}))} />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('events/recent/name').set(recentEvent.name);
                    }}>Save</button>
                </div>
                <div className='flex gap-2 items-center'>
                    <label>description:</label>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={recentEvent.description} onChange={e=>setrecentEvent(prevState => ({...prevState,description: e.target.value}))} />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('events/recent/description').set(recentEvent.description);
                    }}>Save</button>
                </div>
                <div className='flex gap-2 items-center'>
                    <label>imageUrl:</label>
                    <input className='w-full bg-gray-200 focus:outline-none px-2 py-2' type="text" value={recentEvent.imageUrl} onChange={e=>setrecentEvent(prevState => ({...prevState,imageUrl: e.target.value}))} />
                    <button className='bg-blue-400 text-white px-4 py-2 font-semibold'
                    onClick={()=>{
                        firebase.database().ref('events/recent/imageUrl').set(recentEvent.imageUrl);
                    }}>Save</button>
                </div>

            </div>
        </div>

    );
  } else {

    return (

        <section className="w-full h-screen flex justify-center items-center">
            <div className="bg-gray-50 p-5 border">
                <div className="flex flex-col text-sm">
                    <label>Username:</label>
                    <input value={username} onChange={event =>setusername(event.target.value)}
                     className='bg-gray-100 px-4 py-2 rounded-md input-border focus:outline-none col-span-2' placeholder='Enter Username' type="text" />
                </div>
                <div className="flex flex-col text-sm">
                    <label>Password:</label>
                    <input value={password} onChange={event =>setpassword(event.target.value)} className='bg-gray-100 px-4 py-2 rounded-md input-border focus:outline-none' placeholder='Enter Password' type="password" />
                </div>
                <button onClick={login} className="w-1/2 mt-4 py-3 bg-gray-800 text-white rounded-md text-xs" >Log in</button>
            </div>
        </section> 

    )
    
    }

}

  
export default Admin;